import React from 'react';

function App() {
  return (
    <div>
      <h3>Mobility App Static Assets</h3>
      <h5>Please review the documentation in the repository vwfs-ddm-mobilityapp/static-assets-frontend to learn about the directory structures and methods to access the assets.</h5>
    </div>
  );
}

export default App;
